// Sub-types of a Job document
import { DocumentReference, PossiblyReadAsUndefined } from 'firelordjs';

import { CommonFieldsReadRestricted } from '@/lib/firebase/db/@types';
import { Company } from '@/lib/firebase/db/metaTypes';

export type JobFields = {
  name: string;
  brokerCompanyRef: DocumentReference<Company>;
  client: DocumentReference<Company>;
  clientName: string;
  startDatetime: Date;
  sites: JobSitesRefMap;
  sitesIds: string[];
  companyTrucks: JobCompanyTruckData[] | PossiblyReadAsUndefined;
  totalTrucks: number;
  groupsOfOperators: GroupOfOperatorsData[];
  overallStatus: JobOverallStatus;
  productCode: string | PossiblyReadAsUndefined;
  productName: string | PossiblyReadAsUndefined;
  productQuantity: string | PossiblyReadAsUndefined;
  productMaterial: ProductMaterial | PossiblyReadAsUndefined;
  unit: JobUnit;
  stopped: boolean;
  forceJobDispatch: boolean;
  dispatchSchedules: string[] | PossiblyReadAsUndefined;
} & CommonFieldsReadRestricted;

type JobUnit = 'hours' | 'tons' | 'cu yds' | 'loads';

export const jobOverallStatusValues = [
  'INCOMPLETE',
  'NEW',
  'IN_PROGRESS',
  'CANCELLED',
  'IN_REVIEW',
  'IN_DISPUTE',
  'APPROVED',
  'DECLINED',
] as const;
export type JobOverallStatus = (typeof jobOverallStatusValues)[number];
// Job Dispatch Stage is not saved in db but rather derived from other Job fields
export const jobDispatchStageValues = [
  'requiresAttention',
  'dispatched',
  'completed',
] as const;
export type JobDispatchStage = (typeof jobDispatchStageValues)[number];

export const productMaterials = [
  'asphalt',
  'dirt',
  'aggregates',
  'cementitious',
  'other',
] as const;
export type ProductMaterial = (typeof productMaterials)[number];

export type JobCompanyTruckData = {
  companyId: string;
  trucksAcceptedCount: number;
  trucksAssignedCount: number;
  trucksRequestedCount: number;
};

type GroupOfOperatorsData = {
  startDatetimeDifferenceMs: number;
};

type JobSitesRefMap = Record<
  string, // Site id
  {
    lat: number;
    lng: number;
    order: number;
    name: string;
  }
>;
